<template>
    <div id="login">
        <div class="top d_flex">
            <div class="logo" @click="$router.push('/m_index')"><img :src="$store.state.tenantInfo.logo | fullPath" alt=""></div>
            <div class="right_content d_flex">
                <span class="login" @click="$router.push('/m_login')">登录</span>
                <span class="registered" @click="$router.push('/m_register')">注册</span>
                <span @click="show = !show"><img src="@/assets/img/mobile/nav_bar.png" alt=""></span>
            </div>
        </div>
        <div class="banner">
            <img src="@/assets/img/mobile/07.png" alt="">
        </div>
        <div class="form">
            <p class="title">忘记密码</p>
            <div class="inputs">
                <img src="@/assets/img/login-01.png" alt="">
                <input type="text" placeholder="手机" v-model="phone">
            </div>
            <div class="inputs">
                <i class="icon-font el-icon-picture" />
                <input type="text" placeholder="图片验证码" v-model="verifyCode" maxlength="6">
                <div class="imgCodeMode" @click="getVerifyCode" v-loading="verifysignLoding"><img :src="codeUrl" alt=""></div>
            </div>
            <div class="inputs">
                <i class="icon-font el-icon-message"></i>
                <input type="text" v-model="code" placeholder="手机验证码" maxlength="6">
                <div class="pointer po1" @click="sendCode" v-if="verifysignShow">发送验证码</div>
                <div class="pointer po2" v-else>{{getVerification}}</div>
            </div>
            <div class="inputs">
                <img src="@/assets/img/login-03.png" alt="">
                <input type="password" v-model="password" placeholder="密码">
            </div>
            <div class="inputs">
                <img src="@/assets/img/login-03.png" alt="">
                <input type="password" v-model="confirmPassword" placeholder="确认密码">
            </div>
            <div class="btn" @click="submit">确定</div>
        </div>
        <transition name="fade">
            <div class="mask" v-if="show" @click="show=false">
            </div>
        </transition>
        <transition name="fade1">
            <div class="mask_left" v-if="show">
                <div @click="$router.push('/m_index')">首页</div>
                <div @click="$router.push('/m_goodsall')">礼品商城</div>
                <div @click="$router.push('/member/index')">会员中心</div>
                <div @click="$router.push('/member/vip')">收费标准</div>
                <div @click="$router.push('/member/guide')">代发指南</div>
                <!-- <div v-if="$store.state.showOtherSite" @click="$router.push('/member/other_site')">源码搭建</div> -->
                <div @click="openQQ">在线客服</div>
            </div>
        </transition>
    </div>
</template>

<script>
import rsa from '@/utils/rsa'

export default {
    name: '',
    data() {
        return {
            show:false,
            phone: '',
			password: '',
			confirmPassword:'',
			codeUrl: '',
			verifyCode: '',
			verifysign: '',
			verifysignLoding: false,
			verifysignShow: true,
			getVerification: '60s后重新获取',
			code:'',
			sign:'',
			loading:false,//防重复点击
			advert:{},
        }
    },
    created(){
        this.getVerifyCode();
        // this.IsPC();
    },
    mounted(){},
    watch: {},
    methods: {
        IsPC(){  
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(flag){
                this.$router.push('/forget_password')
            }
        },
        openQQ() {
            var url = 'tencent://message/?uin=' + this.$store.state.tenantInfo.qq + '&amp;Site=&amp;Menu=yes';
            window.open(url,'_self')
        },
        sendCode(){
            let reg = 11 && /^[1][0-9][0-9]{9}$/;
            if(!reg.test(this.phone)) {
                this.$message.warning('请输入正确的手机号码')
                return;
            };
            if(this.verifyCode == ''){
                this.$message.warning('请输入图片验证码')
                return
            }
            this.$request.post({
                url: '/sms/public/sendCode',
                params: {
                    phone: this.phone,
                    verifyCode: this.verifyCode,
                    verifySign: this.verifysign
                },
                success:res => {
                    this.sign = res
                    this.$message.success('发送成功')
                    let time = 60
                    this.verifysignShow = false
                    let Time = setInterval(() => {
                        if(time == 1){
                            this.verifysignShow = true
                            this.getVerifyCode()
                            clearInterval(Time)
                        }else{
                            time--
                            this.getVerification = `${time}s后重新获取`
                        }
                    },1000)
                }
            })
        },
        getVerifyCode(){
            this.verifysignLoding = true
            this.$axios.get('/public/verifyCode?' + Math.random(),{responseType: 'arraybuffer'}).then(res => {
                let codeImg = 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data,byte) => data + String.fromCharCode(byte), ''))
                this.codeUrl = codeImg
                this.verifysign = res.headers.sign
                this.verifysignLoding = false
            })
        },
        submit(){
			if(this.loading) return;
			this.loading = true;
			const loading = this.$loading();
			let reg = 11 && /^[1][0-9][0-9]{9}$/;
            if(!reg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号码')
				this.loading = false;
				loading.close();
                return;
			};
			if(this.verifyCode == ''){
				this.$message.warning('请输入图片验证码')
				this.loading = false;
				loading.close();
				return
			}
			if(this.code == ''){
				this.$message.warning('请输入手机验证码')
				this.loading = false;
				loading.close();
				return
			}
			if(this.sign==''){
				this.$message.warning('请点击发送验证码');
				this.loading = false;
				loading.close();
				return
			}
			if(this.password == ''){
				this.$message.warning('请输入新密码')
				this.loading = false;
				loading.close();
				return
			}
			if(this.confirmPassword!=this.password){
				this.$message.warning('新密码与确认密码不一致')
				this.loading = false;
				loading.close();
				return
			}
			this.$request.post({
				url:'/bus/info/public/forgotPassword',
				params:{
					phone: rsa.encryptByPublicKey(this.phone),
					password: rsa.encryptByPublicKey(this.password),
					code: this.code,
					sign: this.sign
				},
				success:res => {
					this.$router.push('/m_login');
				},
				finally: () => {
					this.loading = false;
					loading.close();
				}
			})
		},
    }
}
</script>

<style lang="scss" scoped>
#login{
    min-height: 100%;
    background: #DE2216;
    .top{
        padding: 0 14px;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        height: 44px;
        .logo{
            align-items: center;
            width: 57.6px;
            img{
                width: 100%;
                display: block;
            }
        }
        .right_content{
            align-items: center;
            span{
                &:last-child{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    img{
                        width: 100%;
                    }
                }
            }
            .login{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: #FF8800;
                border-radius: 12px;
                padding: 2px 10px;
            }
            .registered{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF8800;
                padding: 2px 10px;
                border: 1px solid #ff8800;
                border-radius: 12px;
                margin: 0 14px 0 5px;
            }
        }
    }
    .banner{
        img{
            width: 100%;
            display: block;
        }
    }
    .form{
        width: 80%;
        margin: auto;
        background: #fff;
        border-radius: 2px;
        padding-bottom: 20px;
        transform: translateY(-20px);
        .title{
            color: #8c8c8c;
            font-size: 16px;
            text-align: center;
            padding-top: 30px;
            margin-bottom: 20px;
        }
        .inputs{
            position: relative;
            margin:0 14px 15px;
            border: 1px solid #e1e1e1;
            height: 36px;
            .pointer{
                position: absolute;
                top: 0;
                right: 0;
                color: #fff;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                padding: 0 7px;
            }
            .po1{
                background: #f80;
                &:hover{
                    cursor: pointer;
                }
            }
            .po2{
                background: #ffcf99;
                &:hover{
                    cursor: default; 
                }
            }
            img{
                width: 25px;
                display: inline-block;
                margin: 0 5px;
            }
            input{
                border: none;
                color: #666;
                outline: none;
                height: 36px;
                font-size: 16px;
                width: 80%;
                vertical-align: bottom;
            }
            .imgCodeMode{
                height: 36px;
                position: absolute;
                top: 0;
                right: 0;
                img{
                    margin: 0;
                    height: 100%;
                    width: auto;
                    display: block;
                }
            }
            .icon-font{
                font-size: 18px;
                margin: 8px;
                color: #cacaca;
            }
            
        }
        .rem{
            font-size: 14px;
            margin-top: -7px;
            margin-bottom: 13px;
            padding-left: 14px;
            .protocol{
                color: #f80;
                cursor: pointer;
            }
        }
        .btn{
            line-height: 38px;
            background: #f80;
            color: #fff;
            text-align: center;
            border-radius: 4px;
            margin-left: 14px;
            margin-right: 14px;
        }
        .other{
            margin-top: 10px;
            font-size: 12px;
            color: #666;
            margin: 10px 14px 0px;
            a:last-child{
                margin-left: 5px;
            }
        }
    }
    .mask{
        position: fixed;
        top: 44px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
    }
    .mask_left{
        width: 35%;
        text-align: center;
        position: fixed;
        top: 44px;
        background: #fff;
        bottom: 0;
        right: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        div{
            padding: 9px 0;
            border-bottom: 1px solid #eee;
        }
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade1-enter, .fade1-leave-to {
        transform: translateX(100%);
    }
    .fade1-enter-active, .fade1-leave-active {
        transition: all .5s;
    }
}
</style>